import $ from 'jquery';
import 'adminlte/dist/js/adminlte.min';

window.$ = $;
window.jQuery = $;

const toggleDarkMode = () => {
    if(window.sessionStorage.getItem('dark-mode')) {
        $('body').removeClass('dark-mode');
        window.sessionStorage.setItem('dark-mode', 0)
    }
    else {
        window.sessionStorage.setItem('dark-mode', 1)
        $('body').addClass('dark-mode');
    }
}

if(window.sessionStorage.getItem('dark-mode') === "1" || window.matchMedia('(prefers-color-scheme: dark)').matches) {
    $('body').addClass('dark-mode');
}

$('#dark-mode-switch').on('click', function() {
    toggleDarkMode()
})
